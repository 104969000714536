import React,{ useEffect,useState } from 'react'
import { useLocation } from "@reach/router"

import SEOMetaData from '../../../components/background/SEOMetaData.js';
import {InterestTracker} from "../../../components/background/InterestTracker.js";

import Tier3Layout from "../../../components/content/Tier3Layout"
import TravelServicesAgreementV202109A from "../../../components/content/agreements/travelservices202109A.js"; 


import '../../../styles/tier3.css'


const TravelServicesAgreement = () => {
    const pageData = {
                        headline:{
                            text:<>Customer Agreement</>,
                            id:'tcmaster'
                        },
                        sectionnav:{section:'agreements'},
                        seo:{
                            title:'General Terms and Conditions for Travel Related Services',
                            description:'',
                            // image:'/images/headerimage-spiritualrestoration.jpg'
                        }
                    }; 


    let location = useLocation(); 
    useEffect(() => {
        InterestTracker('TnC Master Agreement',location); 
    },[location]);

    const [showConsultationForm,setShowConsultationForm] = useState(false); 

  return (
    <main>
        <SEOMetaData title={pageData.seo.title} description={pageData.seo.description} image={pageData.seo.image} />
        <Tier3Layout headline={pageData.headline} sectionnav={pageData.sectionnav} showConsultForm={showConsultationForm} onCloseConsultForm={()=>{setShowConsultationForm(false)}}>

            <article>
                <TravelServicesAgreementV202109A />
            </article>

        </Tier3Layout>
    </main>
  )
}


export default TravelServicesAgreement;

